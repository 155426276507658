let categoryLinks = null;
let colorItems = null;
const grid = document.querySelector('.grid');

let colorsArray = [];

function categoryFilterHandlers() {
	categoryLinks.forEach(link => {
		const category = link.id;
		link.addEventListener('click', () => filterByCategory(category));
	});
}


function colorFilterHandlers() {
	colorItems.forEach(item => {
		item.addEventListener('click', function handleClick(event) {
			clearActiveGridItems();

			const elClass = this.classList;
			// add or remove tick active state from color
			if (elClass.contains('active')) {
				elClass.remove('active');
				grid.classList.remove('grid--hide-items');
			} else {
				elClass.add('active');
			}

			filterByColor(event.target.dataset.color);
		})
	})
}


/**
 * @description Hide all grid items except the ones where the clicked color matches the 'data-color' attribute.
 * @param {(String|Array)} color
 */
function filterByColor(color) {
	if (!color) return;

	if (Array.isArray(color) === true) {
		colorsArray.push(...color);
		// add active class for checkmark on color items
		colorItems.forEach(item => {
			if (colorsArray.includes(item.dataset.color)) {
				item.classList.add('active');
			}
		})
	} else {
		const hexCode = color;
		const colorIndex = colorsArray.indexOf(hexCode);

		// remove color from array if it has already been selected.
		if (colorIndex !== -1) {
			colorsArray.splice(colorIndex, 1);
		} else {
			colorsArray.push(hexCode);
		}
	}

	// update URL with selected hex code
	const encodeHexCodes = encodeURIComponent(colorsArray.join(','));
	window.history.pushState({}, "", `?color=${encodeHexCodes}`);

	if (colorsArray.length === 0) {
		grid.classList.remove('grid--hide-items');
		window.history.pushState({}, "", '/');
		return;
	}

	grid.classList.add('grid--hide-items');
	colorsArray.forEach(hex => {
		const matches = document.querySelectorAll(`img[data-color="${hex}"]`);
		matches.forEach(match => {
			showParentGridItem(match);
		});
	});
}


/**
 * @param {String} category - They type of category.
 */
function filterByCategory(category) {
	if (!category) return;

	const type = category.toLowerCase();
	const categoryEl = document.querySelectorAll(`[data-category*="${type}"]`);

	colorsArray = [];

	clearActiveGridItems();
	clearColorActiveClass();

	if (type === 'all') {
		grid.classList.remove('grid--hide-items');
		window.history.pushState({}, "", '/');
		return;
	}

	// If a category item's name is the same as the type being filtered, make sure it's clicked.
	categoryLinks.forEach(link => {
		if (link.id === type) link.click()
	});

	// update URL with selected category type
	window.history.pushState({}, "", `?category=${type}`);

	grid.classList.add('grid--hide-items');
	categoryEl.forEach(el => {
		showParentGridItem(el);
	})
}


/**
 * @description Simulate clicking 'All' in filter category.
 */
function resetFilterByCategory() {
	document.querySelector('.nav__item #all').click();
}


/**
 * @description Add a class of 'show' to parent element with a class of 'grid__item'.
 * @param {Element} el
 */
function showParentGridItem(el) {
	el.parentElement.closest('.grid__item').classList.add('show');
}


/**
 * @description Remove class of 'show' from active grid items
 */
function clearActiveGridItems() {
	const gridItems = document.querySelectorAll('.grid__item');
	gridItems.forEach(item => item.classList.remove('show'));
}


/**
 * @description Remove active class from color swatches
 */
function clearColorActiveClass() {
	colorItems.forEach(item => item.classList.remove('active'))
}


/**
 * @description Get value from a specified URL query key. i.e. /?key=value
 * @param {String} key
 * @returns {(Number|String|Array)} Either a number or a string.
 */
function getQueryParameter(key) {
	const urlParams = new URLSearchParams(window.location.search);
	const value = (key === 'color') ? toArray(urlParams.getAll(key)) : urlParams.get(key);

	if (urlParams.get(key) === null) return false;

	function toArray(val) {
		return val.toString().split(',');
	}

	return Number.isNaN(Number(value)) ? value : parseInt(value, 10);
}


function init() {
	const category = getQueryParameter('category');
	const color = getQueryParameter('color');
	categoryLinks = document.querySelectorAll('.nav__category');
	colorItems = document.querySelectorAll('span.color');
	filterByCategory(category);
	filterByColor(color);
	categoryFilterHandlers();
	colorFilterHandlers();
}


export default {
	init,
	getQueryParameter,
	resetFilterByCategory,
}