import loaderAnimation from "./loaderAnimation";
import filesLoad from "./filesLoad";
import imageGallery from "./imageGallery";
import offCanvasSidebar from "./offCanvasSidebar";
import gridHover from "./gridHover";

const {
	loadingTransition,
} = loaderAnimation;


const {
	gridsHTML,
} = filesLoad;

const {
	mediaGalleryHTML,
	addThumbnailCtrls,
} = imageGallery;

const {
	hideOffcanvas,
	offcanvasBackdrop,
} = offCanvasSidebar;

const {
	updateImageBorderColor,
} = gridHover;

let filesArray = [];
let designers = [];

function toggleOverlay(overlayIsActive, overlay = null, projectColor = null) {
	const ovrly = overlay || document.querySelector('.overlay');
	loadingTransition(ovrly, overlayIsActive, projectColor);
}


/**
 * @description Find a project using their id.
 * @param {String} slug
 * @returns {Object} The object contain information about a particular project.
 */
function getProjectBySlug(slug) {
	return filesArray.find(project => project.slug === slug);
}


/**
 * @description Update the URL's query parameter with the value from the element's href.
 * @param {Object} self - The 'this' object that is executing the current piece of code.
 */
function setQueryParameter(self) {
	const queryString = self.getAttribute('href');
	window.history.pushState({}, "", queryString);
}

function getSlugFromUrl() {
	const match = window.location.pathname.match(/\/project\/(.+)/);
	return match ? match[1] : null;
}

function renderProjectDetails() {
	const gridEl = document.querySelector('.overlay__related-projects .grid');
	const galleryEl = document.querySelector('.overlay__content .gallery');
	const project = getProjectBySlug(getSlugFromUrl());
	const media = project.Media[0].fields;

	const {
		Media: subProjects,
	} = project;
	const title = subProjects[0].fields.Name;
	const year = media.Year ? media.Year.join(', ') : '';
	const tags = media.Tags;
	const description = media.Summary;

	let category = subProjects[0].fields.Category || [];
	category = category.filter(cat => cat !== null);
	category.join(', ');

	const use = {
		blog: media['In use - Paystack Blog'],
		twitter: media['In use - Twitter'],
		instagram: media['In use - Instagram'],
	};

	const projectDesigners = subProjects[0].fields.allDesigners || [];

	const galleryHTML = mediaGalleryHTML(subProjects);
	const relatedProjectsArray = relatedProjects(category);
	const ProjectInfoHTML = projectInformationHTML(title, category, year, description, projectDesigners, tags, use);

	document.querySelector('.overlay__content').replaceChild(galleryHTML, galleryEl);
	document.querySelector('.overlay__project-information .main').innerHTML = ProjectInfoHTML;

	if (relatedProjectsArray.length > 0) {
		const projectsHTML = relatedProjectsHTML(relatedProjectsArray);
		document.querySelector('.overlay__related-projects').replaceChild(projectsHTML, gridEl);
	}
}


/**
 * @description Generate the HTML and include the information for a project detail layout
 * @param {String} title
 * @param {Array} cat - The associated project's category
 * @param {String} year
 * @param {String} desc
 * @param {Array} designersArr
 * @param {Array} tagsArray
 * @param {Array} use
 */
function projectInformationHTML(title, cat, year, desc, designersArr, tagsArray, use) {
	let designersHTML = '';
	let inUse = [];
	let tagsHTML = tagsArray ? tagsArray.map(tag => `<a href="" class="link--tag">${tag}</a>`) : [];

	const category = cat.join(', ');
	const description = desc || '';
	const [designersArray] = (designersArr.length >= 1) ? designersArr: [designersArr];

	if (designersArray.length > 0) {
		designersHTML = designersArray.map(designer => `<a href="/${designer.fields.slug}" class="link--dotted is-designer" data-slug="${designer.fields.slug}">${designer.fields.Name}</a>`);
		designersHTML = designersHTML.join('');
	}

	const entries = Object.entries(use);
	for (let i = 0; i < entries.length; i += 1) {
		const [key, value] = entries[i];
		if (value !== undefined) {
			const link = `<a href="${value}" class="link--dotted" target="_blank">${key}</a>`;
			inUse.push(link);
		}
	}

	tagsHTML = tagsHTML.join('');
	inUse = inUse.join(' ');

	return `<div>
    <h3>${title}</h3>
    <p class="sub-header">${description}</p>
    <div class="tags">${tagsHTML}</div>
  </div>
  <div>
    <ul class="overlay__project-details">
      ${inUse ? `<li><span class="label">In use</span><span>${inUse}</span></li>` : ''}
      <li><span class="label">Category</span> <span class="category">${category}</span></li>
      <li><span class="label">Year</span> <span class="year">${year}</span></li>
      <li><span class="label">Designers</span> <span class="designers">${designersHTML}</span></li>
    </ul>
  </div>`;
}


/**
 * @description Fetch a number of related projects based on specified category.
 * @param {String} category
 * @param {Number} number
 * @returns {Object[]}
 */
function relatedProjects(category, number = 5) {
	const projects = filesArray.filter(project =>
		project.Category.some(name => category.includes(name)),
	).slice(0, number);
	return projects;
}


function relatedProjectsHTML(projectsArray) {
	const gridElement = document.createElement('div');

	gridElement.className = 'grid';
	gridElement.addEventListener('mouseover', updateImageBorderColor);

	projectsArray.forEach(project => {
		const media = project.Media[0].fields;
		const firstImage = media.Attachments[0] ? media.Attachments[0].url : '';

		const link = document.createElement('a');
		const projectDesigners = project.allDesigners.map(arr => arr.fields.Name);
		projectDesigners.join(', ');

		link.href = `/project/${project.slug}`;
		link.className = 'grid__item';
		link.addEventListener('click', function handleClick() {
			showProjectDetail(this);
		});
		link.innerHTML = `<div class="image">
      <img class="grid__thumbnail" src="${firstImage}" alt="${project.description}" data-category="${project.category}" data-color="${project['Dominant Color']}" data-designers="${projectDesigners}" />
    </div>`;

		gridElement.appendChild(link);
	});

	return gridElement;
}

function handleProjectDetail(projectColor) {
	setTimeout(() => {
		renderProjectDetails();
		designerLinkHandlers();
		addThumbnailCtrls();
	}, 500)

	toggleOverlay(true, null, projectColor);
}

function showProjectDetail(thiOj, event) {
	const e = event || window.event;
	e.preventDefault();

	const projectColor = e.target.dataset.color;
	setQueryParameter(thiOj);
	handleProjectDetail(projectColor)
}


function showDesignerProfile(slug) {
	const files = getProjectByDesigner(slug);

	renderDesignerGrids(files);
	attachGridsEventListener();
	renderDesignerInfo(slug);
	hideOffcanvas.call(offcanvasBackdrop, '');
	toggleDesignerOverlay(true);
}


function toggleDesignerOverlay(overlayIsActive) {
	const overlay = document.querySelector('.profile.overlay');
	loadingTransition(overlay, overlayIsActive);
}


/**
 * @description Render the project grid items by a designer to the DOM.
 * @param {Object[]} files - An array of objects containing all projects.
 */
function renderDesignerGrids(files) {
	const html = gridsHTML(files);
	const grid = document.querySelector('.profile .grid');
	grid.innerHTML = html;
}


function attachGridsEventListener() {
	const gridItems = document.querySelectorAll('.profile .grid__item');
	gridItems.forEach(item => {
		item.addEventListener('click', function handleClick(event) {
			event.preventDefault();
			showProjectDetail(this);
		})
	});
}


function getProjectByDesigner(slug) {
	const result = [];
	filesArray.forEach(project => {
		project.allDesigners.forEach(designer => {
			if (designer.fields.slug === slug) {
				result.push(project);
			}
		});
	});
	return result.flat(1);
}

function getDesignerInfo(slug) {
	const found = designers.find(designer => {
		if (designer.Name) {
			return designer.slug === slug;
		}
		return false;
	});

	return found;
}

function renderDesignerInfo(slug) {
	const designerInfo = getDesignerInfo(slug)

	const photo = designerInfo['Profile Picture'] ? designerInfo['Profile Picture'][0].url : '/assets/images/no-thumbnail.png';
	const infoEl = document.querySelector('.profile .profile__info');
	const portfolio = designerInfo.Portfolio;

	const html = `<picture><img class="profile__thumbnail" src="${photo}" alt=""></picture><h3 class="profile__name">${designerInfo.Name}</h3>
  ${!!portfolio === true ? `<a class="link--dotted" target="_blank" href="https://${portfolio}">Portfolio</a>` : ''}

  <p>${designerInfo.Bio || ''}</p>`;

	infoEl.innerHTML = html;
}


function designerLinkHandlers() {
	const designersLinks = document.querySelectorAll('.is-designer');
	designersLinks.forEach(link => {
		link.addEventListener('click', e => {
			e.preventDefault();
			window.history.pushState({}, "", `/${e.target.dataset.slug}`);
			showDesignerProfile(e.target.dataset.slug);
		})
	})
}

function init(designersArr, filesArr) {
	filesArray = filesArr;
	designers = designersArr;

	const activeProjectSlug = getSlugFromUrl()
	if (activeProjectSlug) {
		const gridItem = document.querySelector(`[data-slug="${activeProjectSlug}"]`);

		if (gridItem) {
			handleProjectDetail(gridItem.dataset.color)
		}
	}
}


export default {
	init,
	toggleOverlay,
	showProjectDetail,
	showDesignerProfile,
	renderProjectDetails,
	getProjectBySlug,
};