import filter from "./filter";

const { resetFilterByCategory} = filter;
const input = document.querySelector('.offcanvas-sidebar__search');

input.addEventListener('input', event => {
	resetFilterByCategory();
	const query = event.target.value.toLowerCase().trim();
	const gridContainer = document.querySelector('.grid');
	const thumbnails = document.querySelectorAll('.grid__thumbnail');

	gridContainer.classList.add('grid--hide-items');

	thumbnails.forEach(thumbnailItem => {
		thumbnailItem.closest('.grid__item').classList.remove('show');

		const match = thumbnailItem.alt.includes(query) ||
                  thumbnailItem.dataset.color.includes(query) ||
                  thumbnailItem.dataset.designers.includes(query);

		if (match) {
			thumbnailItem.closest('.grid__item').classList.add('show');
		}
	});
});