const loader = document.querySelector('.loader');
const lastAnimation = document.querySelector('.loader__slide');
const nav = document.querySelector('.nav__hex-code');

document.fonts.load("16px Boing").then(() => {
	loader.classList.add('active');
	lastAnimation.addEventListener('animationend', () => {
		loader.remove();
		nav.classList.add('show');
	})
});


/**
 * @description Choose a hex code at random from an array of paystack colors.
 * @returns {String} Containing color hex code
 */
function getRandomColor(projectColor) {
	const colors = ['#011b33', '#00C3F7', '#22D34B'];
	const noOfColors = colors.length;
	const randomIndex = Math.floor(Math.random() * noOfColors);
	return projectColor || colors[randomIndex];
}


function loadingTransition(overlayEl, overlayIsActive, projectColor = null) {
	const loaderScreen = document.querySelector('.overlay-loader');
	const isActive = overlayIsActive;
	const color = projectColor || getRandomColor();
	const videoViewer = document.querySelector('.gallery .gallery__viewer-video');
	const root = document.documentElement;

	loaderScreen.style.background = color;
	loaderScreen.classList.add('loading');

	loaderScreen.addEventListener('animationend', function handleAnimationEnd(event) {
		const overlay = overlayEl;
		this.classList.add('loaded');
		overlay.scrollTo(0,0);
		overlay.classList.add('active');

		const allOverlays = document.querySelectorAll('.overlay');
		allOverlays.forEach(overlayItem => {
			const overlayStyle = overlayItem.style;
			overlayStyle.zIndex = 11;
		});

		if (isActive === true) {
			root.style.overflow = 'hidden';
			overlay.style.zIndex = 12;
			overlay.classList.add('active');
		}

		if (isActive === false) {
			if (document.contains(videoViewer)) videoViewer.pause();

			overlay.classList.remove('active');
		}

		this.classList.remove('loading');
		if (event.animationName === 'slideOut') this.classList.remove('loaded');

		const activeOverlays = [...document.querySelectorAll('.overlay')].filter(overlayItem => overlayItem.classList.contains('active'))
		if (activeOverlays.length < 1) root.removeAttribute("style");
	});
}


export default { loadingTransition };