import filesLoad from "./filesLoad";

const { grid } = filesLoad;
const images = document.querySelectorAll('.image');

grid.addEventListener('mouseover', updateImageBorderColor);

images.forEach(image => {
	image.addEventListener('pointerleave', () => updateImageBorderColor);
});


/**
 * @param {Event} event
 */
function updateImageBorderColor(event) {
	const navHexCode = document.querySelector('.nav__hex-code');
	const stackBlue = '#011b33';
	if (event.target.classList.contains('grid__thumbnail')) {
		const imageColor = event.target.dataset.color;

		// update hex code when user's mouse is over an image
		this.style.color = imageColor ;
		navHexCode.textContent = (imageColor !== '') ? imageColor : stackBlue;
		return;
	}
	navHexCode.textContent = stackBlue;
}

export default { updateImageBorderColor };