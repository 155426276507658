import "./assets/scss/main.scss";
import "./assets/js/scroll";
import "./assets/js/search";
import filesLoad from "./assets/js/filesLoad";
import filter from "./assets/js/filter";
import overlayPanel from "./assets/js/overlayPanel";
import designerProfile from "./assets/js/designerProfile";
import projectDetail from "./assets/js/projectDetail";
import imageGallery from "./assets/js/imageGallery";

const {
	getDesignersInfo,
	getFiles,
} = filesLoad;
const {
	showDesignerProfile,
} = overlayPanel;


async function fetchData() {
	const designersData = await getDesignersInfo();
	const filesData = await getFiles();

	return {
		designersData,
		filesData,
	}
}

fetchData().then(data => {
	const {
		designersData,
		filesData,
	} = data;
	filesLoad.init(filesData);
	filter.init();
	overlayPanel.init(designersData, filesData);
	designerProfile.init(designersData);
	projectDetail.init(filesData);
	imageGallery.init(showDesignerProfile);
});