import filter from "./filter";
import overlayPanel from "./overlayPanel";

const { getQueryParameter} = filter;
const { toggleOverlay, showProjectDetail, renderProjectDetails } = overlayPanel;

let filesArray = null;


// Modifies the browser's 'back' & 'forward' button behavior
function maintainSessionHistory() {
	window.addEventListener('popstate', () => {
		const isOverlayActive = document.querySelector('.overlay.active');

		if (getQueryParameter('p') !== false) {
			toggleOverlay(true);
			setTimeout(() => {
				renderProjectDetails(filesArray);
			}, 500)
			return;
		}

		if (document.contains(isOverlayActive)) {
			toggleOverlay(false);
			
		}
	})
}


function closeProjectOverlay() {
	if (document.querySelector('.profile.active') === null) {
		window.history.pushState({}, '', '/');
	} else {
		window.history.back();
	}
	toggleOverlay(false);
}


function init(files) {
	maintainSessionHistory();
	filesArray = files;

	const closeButton = document.querySelector('.overlay.project .overlay__close-button');
	closeButton.addEventListener('click', closeProjectOverlay);
	const gridItems = document.querySelectorAll('.grid__item');
	gridItems.forEach(item => {
		item.addEventListener('click', function handleClick() {
			showProjectDetail(this);
		})
	});
}


export default { init };