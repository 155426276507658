let filesArray = null;
const grid = document.querySelector('.grid');

/**
 * Fetch all projects.
 * @returns {Promise<any[]>}
 */
async function fetchFiles() {
	const url = '/.netlify/functions/projects';
	const response = await fetch(url, {
		timeout: 30000,
	});
	if (response.ok) {
		return response.json();
	}

	return []
}

/**
 * Fetch all designers.
 * @returns {Promise<any[]>}
 */
async function fetchDesignersInfo() {
	const url = '/.netlify/functions/designers';
	const response = await fetch(url, {
		timeout: 30000,
	});
	if (response.ok) {
		return response.json();
	}

	return []
}


/**
 * @description Generate the html for all the project grids.
 * @param {Object[]} filesArr - An array of objects containing all projects.
 */
function gridsHTML(filesArr) {
	const gridItems = [];

	// Omit files that have no attachments
	const files = filesArr.filter(file => {
		if (file.Media[0] !== undefined) {
			return file.Media[0].fields.Attachments;
		}
		return false;
	});

	files.forEach(file => {
		// use the first image as cover image.
		const media = file.Media[0].fields.Attachments[0];
		let mediaSrc = null;

		if (media.type !== 'video/mp4') {
			mediaSrc = media.thumbnails.large;
		} else {
			mediaSrc = media;
		}

		Object.assign(file, {
			mediaSrc,
			media,
		})

		const item = gridItemHTML(file);
		gridItems.push(item);
	});
	return gridItems.join('');
}


/**
 * @description Render the project grids html to the DOM.
 * @param {Object[]} files - An array of objects containing all projects.
 */
function renderGrids(files) {
	const html = gridsHTML(files);
	grid.insertAdjacentHTML('afterbegin', html);
}


/**
 * @description Render the name tags of all designers (visible in the sidebar) to the DOM.
 * @param {Object[]} files - An array of objects containing all projects.
 */
function renderDesigners(files) {
	const designersContainer = document.querySelector('.offcanvas-sidebar__grid.designers');

	// assign the names of all designers to an array
	const designersArray = getAllDesigners(files);
	const designersItems = designersArray.map(designer => `<li><a class="link--outline" href="/${designer.toLowerCase()}">${designer}</a></li>`);
	designersContainer.insertAdjacentHTML('afterbegin', designersItems.join(''));
}


/**
 * @description Render dominant colors (visible in the sidebar) to the DOM.
 * @param {Object[]} files - An array of objects containing all projects.
 */
function renderColors(files) {
	const colorsContainer = document.querySelector('.offcanvas-sidebar__grid.colors');
	const colorsArray = getAllColors(files);

	const colorsItems = colorsArray.map(color => {
		const checkmark = `<svg class="checkmark" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 2L7 13L2 8" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`;
		const item = `<li><span class="color" style="background: ${color}" data-color="${color}">${checkmark}</span></li>`;
		return item;
	});
	colorsContainer.insertAdjacentHTML('afterbegin', colorsItems.join(''));
}


/**
 * @description Get all designers names from across projects.
 * @param {Object[]} files - An array of objects containing all projects.
 * @return {String[]} An array containing the names of designers.
 */
function getAllDesigners(files) {
	const allDesigners = [];
	for (let i = 0; i < files.length; i += 1) {
		files[i].allDesigners.forEach(designer => allDesigners.push(designer.fields.Name))
	}

	return removeArrayDuplicates(allDesigners);
}


/**
 * @description Get the dominant color hex code from across projects.
 * @param {Object[]} files - An array of objects containing all projects.
 * @return {String[]} An array containing hex code of the dominant colors across projects.
 */
function getAllColors(filesArr) {
	const files = filesArr.filter(file => file['Dominant Color']);
	const allColors = files.map(file => file['Dominant Color']);

	return removeArrayDuplicates(allColors);
}

/**
 * @description Populate a grid item's html template with specified data.
 * @param {object} file
 */
function gridItemHTML(file) {
	const color = file['Dominant Color']
	const designers = file.allDesigners.map(designer => designer.fields.Name).join(',');
	const category = file.Category.join(',').toLowerCase();

	let mediaEl = null;

	if (file.media.type !== 'video/mp4') {
		mediaEl = `<img class="grid__thumbnail" src="${file.mediaSrc.url}" alt="${file.Name}" data-category="${category}" data-color="${color || ''}" data-designers="${designers}" height="300" width="300" loading="lazy" />`;
	} else {
		mediaEl = `<video class="grid__thumbnail" src="${file.mediaSrc.url}" alt="${file.Name}" data-category="${category}" data-color="${color || ''}" data-designers="${designers}" height="300" width="300" loading="lazy"></video>`;
	}

	return `<a href="/project/${file.slug}" class="grid__item" tabindex="3" key="${file.id}" data-slug="${file.slug}">
            <div class="image">
              <span class="grid__thumbnail-title">${file.Name}</span>
              ${mediaEl}
            </div>
          </a>`;
}


/**
 * @description Remove duplicate values from an array.
 * @param {*[]} array
 * @returns {*[]}
 */
function removeArrayDuplicates(array) {
	// flatten one-level deep multi-dimensional array
	const flattened = array.flat();
	// remove duplicates from array
	const result = Array.from(new Set(flattened));
	return result;
}


function hexCodeTooltip() {
	const colors = document.querySelectorAll('.offcanvas-sidebar span.color');
	colors.forEach(color => {
		color.addEventListener('mouseenter', function handleMouseEnter(e) {
			const hex = e.target.dataset.color;
			const span = document.createElement('span');
			span.className = 'tooltip';
			span.textContent = hex;

			this.appendChild(span);
		});
		color.addEventListener('mouseleave', function handleMouseLeave() {
			const span = document.querySelector('.tooltip');
			this.removeChild(span);
		});
	})
}


async function init(files) {
	filesArray = files;
	renderGrids(filesArray);
	renderDesigners(filesArray);
	renderColors(filesArray);
	hexCodeTooltip();
}


export default {
	init,
	getFiles: fetchFiles,
	getDesignersInfo: fetchDesignersInfo,
	grid,
	gridsHTML,
}