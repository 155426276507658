import gridHover from "./gridHover";
import overlayPanel from "./overlayPanel";

const {
	toggleOverlay,
	showDesignerProfile,
} = overlayPanel;
const {
	updateImageBorderColor,
} = gridHover;

function addDesignerProfileHandlers() {
	const designers = document.querySelectorAll('.offcanvas-sidebar__grid.designers a');
	designers.forEach(designer => {
		designer.addEventListener('click', event => {
			const name = event.target.textContent.toLowerCase();
			showDesignerProfile(name);
		});
	})
}


function closeProfileOverlay() {
	if (document.querySelector('.project.active') === null) {
		window.history.pushState({}, '', '/');
	} else {
		window.history.back();
	}
	toggleOverlay(false, document.querySelector('.profile.overlay'));
}


function init() {
	addDesignerProfileHandlers();

	const closeButton = document.querySelector('.profile .overlay__close-button');
	closeButton.addEventListener('click', closeProfileOverlay);

	const gridElement = document.querySelector('.profile .grid');
	gridElement.addEventListener('mouseover', updateImageBorderColor);
}


export default {
	init,
};