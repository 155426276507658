/**
 * @description When a user scrolls, make the bottom nav active.
 */
window.addEventListener('scroll', () => {
	document.querySelector('.nav').classList.add('active');
	document.querySelector('.nav-hex').classList.add('active');
}, { once: true });


/**
 * @description Automatically scroll user to grid section after a few seconds.
 */
const autoScroll = () => {
	const hero = document.querySelector('.hero');
	const grid = document.querySelector('main.container');

	hero.style.setProperty('--hgt', `-${document.documentElement.clientHeight}px`);
	grid.style.setProperty('--hgt', `-${document.documentElement.clientHeight}px`);
	hero.classList.add('slide');
	grid.classList.add('slide');

	hero.addEventListener('animationend', () => {
		hero.remove();
		grid.classList.remove('slide');
		document.body.classList.remove('no-scroll');
	})
};

window.onbeforeunload = () => {
	window.scrollTo(0, 0);
}

document.body.className = 'no-scroll';
setTimeout(() => autoScroll(), 750);
