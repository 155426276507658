const aboutMenuToggle = document.querySelector('.header__about-link');
const searchMenuToggle = document.querySelector('.header__search-link');
const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop');
let sidebarContainer;

offcanvasBackdrop.addEventListener('click', hideOffcanvas);

aboutMenuToggle.addEventListener('click', toggleAboutSidebar);
aboutMenuToggle.addEventListener('keypress', event => {
	if (event.key === 'Enter') toggleAboutSidebar();
});

searchMenuToggle.addEventListener('click', toggleSearchSidebar);
searchMenuToggle.addEventListener('keypress', event => {
	if (event.key === 'Enter') toggleSearchSidebar();
});


/**
 * @description Toggle 'About' sidebar.
 */
function toggleAboutSidebar() {
	sidebarContainer = document.querySelector('.offcanvas-sidebar--left');
	showOffcanvas();
}


/**
 * @description Toggle 'Search' sidebar.
 */
function toggleSearchSidebar() {
	sidebarContainer = document.querySelector('.offcanvas-sidebar--right');
	showOffcanvas();
}


/**
 * @description Slide out sidebar away from viewport.
 */
function hideOffcanvas() {
	if (sidebarContainer) {
		sidebarContainer.classList.remove('show');
		this.classList.remove('show');
	}
}


/**
 * @description Slide in sidebar.
 */
function showOffcanvas() {
	sidebarContainer.classList.add('show');
	offcanvasBackdrop.classList.add('show');

	window.addEventListener('keyup', event => {
		if (event.key === 'Escape') hideOffcanvas.call(offcanvasBackdrop, '');
	});
}


export default { hideOffcanvas, offcanvasBackdrop };