import leftArr from "../images/icons/chevron-left.svg";
import rightArr from "../images/icons/chevron-right.svg";

let showProfile;


function mediaGalleryHTML(mediaArray) {
	const galleryEl = document.createElement('div');
	const thumbnailsHTML = mediaThumbnailsHTML(mediaArray);
	const firstThumbnail = thumbnailsHTML.querySelector('.gallery__thumbnail-item > .gallery__thumbnail-image');
	const galleryViewerEl = document.createElement('div');
	const firstItemIsVideo = firstThumbnail.tagName.toLowerCase() === 'video';

	galleryViewerEl.className = 'gallery__viewer';
	galleryViewerEl.innerHTML = `<figure class="${!firstItemIsVideo ? 'active' : ''}"><img src="${firstThumbnail.src}" alt="" class="gallery__viewer-image" /></figure><video class="gallery__viewer-video ${firstItemIsVideo ? 'active' : ''}" src="${firstThumbnail.src}" autoplay loop></video>`;

	galleryViewerEl.querySelector('figure').addEventListener('click', e => {
		focusedView(e.target.outerHTML);
	});

	galleryViewerEl.addEventListener('mousemove', updateSliderCursor);
	galleryViewerEl.addEventListener('click', browseSlider);

	galleryEl.className = 'gallery';
	galleryEl.appendChild(galleryViewerEl);
	galleryEl.insertBefore(thumbnailsHTML, galleryEl.nextSibling);

	return galleryEl;
}


function galleryCtrl(ctrl) {
	const isLeftArrow = ctrl === 'prev';
	const thumbnails = document.querySelectorAll('.gallery__thumbnail-item');
	const activeIndex = findActiveThumbnail(thumbnails);

	let prevIndex = (activeIndex > -1) ? activeIndex - 1 : 0;
	let nextIndex = (activeIndex > -1) ? activeIndex + 1 : 1;

	if (prevIndex < 1) prevIndex = 0;
	if (nextIndex > thumbnails.length - 1) {
		nextIndex = thumbnails.length - 1;
	}

	if (isLeftArrow) {
		thumbnails[prevIndex].querySelector('.gallery__thumbnail-image').click();
	} else {
		thumbnails[nextIndex].querySelector('.gallery__thumbnail-image').click();
	}
}


function thumbnailCtrl(e) {
	const thumbnails = document.querySelector('.gallery__thumbnails');
	const thumbnailsProp = thumbnails.getBoundingClientRect();
	const nameOfClass = e.target.classList;
	let left = 0;

	if (nameOfClass.contains('left')) {
		left = thumbnails.scrollLeft - thumbnailsProp.width;
	} else {
		left = thumbnails.scrollLeft + thumbnailsProp.width;
	}

	thumbnails.scroll({
		behavior: 'smooth',
		top: 0,
		left,
	});
}


function thumbnailCtrlVisibility() {
	const thumbnails = document.querySelector('.gallery__thumbnails');
	const thumbnailsProp = thumbnails.getBoundingClientRect();
	const endOfThumbnails = Math.ceil(thumbnails.scrollLeft) + thumbnailsProp.width >= thumbnails.scrollWidth;
	const startOfThumbnail = thumbnails.scrollLeft === 0;
	const leftBtn = document.querySelector('.gallery__thumbnail-ctrl.left');
	const rightBtn = document.querySelector('.gallery__thumbnail-ctrl.right');

	if (endOfThumbnails) {
		rightBtn.classList.add('hide');
	} else if (startOfThumbnail) {
		leftBtn.classList.add('hide');
	} else {
		leftBtn.classList.remove('hide');
		rightBtn.classList.remove('hide');
	}
}


function browseSlider(e) {
	const width = document.documentElement.clientWidth;
	const leftHalf = width - (width / 2);
	if (e.target.localName === 'figure') return;
	if (e.clientX <= leftHalf) {
		galleryCtrl('prev')
	} else {
		galleryCtrl('next');
	}
}


function findActiveThumbnail(thumbnails) {
	return [...thumbnails].findIndex(thumbnail => thumbnail.className.includes('active'));
}


function focusedView(imgSrc) {
	const overlay = document.createElement('div');
	const closeBtn = document.createElement('button');

	closeBtn.className = 'overlay__close-button';
	closeBtn.addEventListener('click', () => overlay.remove());

	overlay.className = 'overlay active focused';
	overlay.innerHTML = imgSrc;
	overlay.appendChild(closeBtn);

	document.querySelector('.overlay.active').insertAdjacentElement('beforebegin', overlay);
	document.querySelector('.focused figure').addEventListener('click', () => overlay.remove());
}


function updateSliderCursor(e) {
	const leftCursor = `url("${leftArr}"), auto`;
	const rightCursor = `url("${rightArr}"), auto`;
	const thumbnailItems = document.querySelectorAll('.gallery__thumbnail-item');
	const width = document.documentElement.clientWidth;
	const leftHalf = width - (width / 2);

	function lastThumbnailActive() {
		const lastThumbnail = thumbnailItems[thumbnailItems.length - 1];
		if (lastThumbnail.classList.contains('active')) return true;
		return false;
	}

	function firstThumbnailActive() {
		const firstThumbnail = thumbnailItems[0];
		if (firstThumbnail.classList.contains('active')) return true;
		return false;
	}

	if (e.clientX <= leftHalf) {
		this.style.cursor = !firstThumbnailActive() ? leftCursor : 'default';
		return;
	}
	this.style.cursor = !lastThumbnailActive() ? rightCursor : 'default';
};


// creates a list item for each of the images in media array
function mediaThumbnailsHTML(array) {
	const thumbnailsWrapper = document.createElement('div');
	const thumbnailsEl = document.createElement('ul');
	thumbnailsEl.className = 'gallery__thumbnails';
	thumbnailsWrapper.className = 'thumbnails-wrapper';

	for (let i = 0; i < array.length; i += 1) {
		const attachments = array[i].fields.Attachments;
		if (attachments !== undefined) {
			attachments.forEach(item => {
				const source = item.url;
				const thumbnailItemEl = document.createElement('li');
				const title = array[i].fields.Name || '';
				const summary = array[i].fields.Summary || '';
				const category = array[i].fields.Category ? array[i].fields.Category.join(', ') : '';
				const year = array[i].fields.Year ? array[i].fields.Year.join(', ') : '';
				let designers = [];
				if (array[i].fields.allDesigners[0] !== undefined) {
					designers = array[i].fields.allDesigners[0].map(designer => designer.fields.Name);
				}
				designers.join(', ');

				thumbnailItemEl.className = 'gallery__thumbnail-item';

				if (item.type === 'video/mp4') {
					thumbnailItemEl.innerHTML = `<video src="${source}" class="gallery__thumbnail-image" data-title="${title}" data-summary="${summary}" data-category="${category}" data-year="${year}" data-designers="${designers}"></video>`;
				} else {
					thumbnailItemEl.innerHTML = `<img src="${source}" alt="" class="gallery__thumbnail-image" data-title="${title}" data-summary="${summary}" data-category="${category}" data-year="${year}" data-designers="${designers}"/>`;
				}

				if (thumbnailsEl.children.length === 0) thumbnailItemEl.classList.add('active');

				thumbnailItemEl.addEventListener('click', updateViewer);
				thumbnailsEl.appendChild(thumbnailItemEl);
			});
		}
	}
	thumbnailsWrapper.appendChild(thumbnailsEl)
	return thumbnailsWrapper;
}


function addThumbnailCtrls() {
	const thumbnailsWrapper = document.querySelector('.thumbnails-wrapper');
	const thumbnails = document.querySelector('.gallery__thumbnails');
	const fragment = document.createDocumentFragment();
	const leftBtn = document.createElement('button');
	const rightBtn = document.createElement('button');
	let btnsArr = null;

	// Check if thumbnail container can scroll horizontally.
	if (thumbnails.scrollWidth === Math.ceil(thumbnails.getBoundingClientRect().width)) return;

	leftBtn.addEventListener('click', thumbnailCtrl);
	rightBtn.addEventListener('click', thumbnailCtrl);
	thumbnails.addEventListener('scroll', thumbnailCtrlVisibility);

	[leftBtn.className, rightBtn.className] = ['gallery__thumbnail-ctrl left hide', 'gallery__thumbnail-ctrl right'];
	fragment.appendChild(leftBtn);
	fragment.appendChild(rightBtn);
	btnsArr = Array.from(fragment.children);
	for (let i = 0; i < btnsArr.length; i += 1) {
		thumbnailsWrapper.insertAdjacentElement('afterBegin', btnsArr[i]);
	}
}


function updateViewer(event) {
	const thumbnails = document.querySelectorAll('.gallery__thumbnail-item');
	const thumbnailsEl = document.querySelector('.gallery__thumbnails');
	const thumbnailSrc = event.target.src;
	const figureEl = document.querySelector('.gallery__viewer figure');
	const videoEl = document.querySelector('.gallery__viewer video');

	thumbnails.forEach(thumbnail => thumbnail.classList.remove('active'));
	this.classList.add('active');
	updateProjectInformation(event.target);

	thumbnailsEl.scroll({
		behavior: 'smooth',
		top: 0,
		left: `${this.offsetLeft - 40}`,
	});

	if (event.target.localName === 'video') {
		figureEl.classList.remove('active');
		videoEl.classList.add('active');
		document.querySelector('.gallery__viewer-video').src = thumbnailSrc;
		return;
	}

	videoEl.pause();
	figureEl.classList.add('active');
	videoEl.classList.remove('active');
	document.querySelector('.gallery__viewer-image').src = thumbnailSrc;
};


function updateProjectInformation(thumbnailItem) {
	const title = document.querySelector('.overlay__project-information h3');
	const description = document.querySelector('.overlay__project-information .sub-header');
	const year = document.querySelector('.overlay__project-details span.year');
	const category = document.querySelector('.overlay__project-details span.category');
	const designers = document.querySelector('.overlay__project-details span.designers');

	title.textContent = thumbnailItem.dataset.title;
	category.textContent = thumbnailItem.dataset.category;
	description.textContent = thumbnailItem.dataset.summary;
	year.textContent = thumbnailItem.dataset.year;
	designers.innerHTML = '';

	thumbnailItem.dataset.designers.split(',').forEach(designer => {
		const name = designer.toLowerCase();
		const link = document.createElement('a');
		link.className = 'link--dotted is-designer';
		link.href = `/${name.toLowerCase()}`;
		link.textContent = name;
		link.addEventListener('click', () => showProfile(name));
		designers.appendChild(link)
	})
}

function getDesignerSlugFromUrl() {
	const path = window.location.pathname
	const projectMatch = path.match(/\/project\/(.+)/);
	const designerMatch = path.match(/^\/([^/]+)$/);

	if (designerMatch && !projectMatch) {
		return designerMatch[1]
	}

	return null;
}

function init(designerProfile) {
	showProfile = designerProfile;

	const designerSlug = getDesignerSlugFromUrl()
	if (designerSlug) {
		showProfile(designerSlug)
	}
}


export default {
	init,
	mediaGalleryHTML,
	galleryCtrl,
	thumbnailCtrl,
	thumbnailCtrlVisibility,
	mediaThumbnailsHTML,
	addThumbnailCtrls,
}